import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class ManagementConsulting extends React.Component {
	render() {
		const siteTitle = 'Management Consulting-Strategy & Planning'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">
									Management Consulting-Strategy &amp; Planning
								</h3>
								<p>
									The services we offer from a technical and project management
									perspective is complemented strongly by our ability to advise
									clients on strategy. Effective strategy needs to be based on a
									strong understanding of markets, regulation and business
									fundamentals.
								</p>
								<h5>
									We add value by assisting your business in formulating a clear
									vision, identify a sustainable competitive advantage and
									developing and implementing your own strategic plan. In
									particular, focusing on:
								</h5>
								<ul>
									<li>Actual vs budget reporting</li>
									<li>Boardroom strategy and analysis</li>
									<li>Business plan development</li>
									<li>Business structure and advice</li>
									<li>Strategy in maintaining competitive advantage</li>
									<li>Long term wealth creation strategies</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ManagementConsulting

export const ManagementConsultingPageQuery = graphql`
	query ManagementConsultingPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
